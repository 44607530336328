<script>
import Layout from "../../layouts/main"; 
import PageHeader from "@/components/page-header";
import appConfig from "@/app.config"; 
import Multiselect from "vue-multiselect";
import {
  required,
} from "vuelidate/lib/validators";

/**
 * Form Validation component
 */
export default {
  page: {
    title: "Form Create room",
    meta: [{ name: "description", content: appConfig.description }]
  },
  components: { Layout, PageHeader,  Multiselect },
  data() {
    return {
      title: "Form Create Room",
      items: [
        {
          text: "Home",
          href: "/home"
        },
        {
          text: "Room",
          href: "/room"
        },
        {
          text: "Create room",
          active: true
        }
      ],
      form: {
        name: null,
        capacity: null,
        description: null,
        location: null
      }, 
      typesubmit: false,
      isError: false,
      errorMessage: null,
      locations: [],
      isLocationNotSelected: false
    };
  },
  validations: {
    form: {
      name: { required },
      capacity: { required },  
      description: { required }
    }
  },
  mounted() {
    this.getLocation()
  },
 
  methods: {
    /**
     * Validation type submit
     */
    // eslint-disable-next-line no-unused-vars
    onSubmit(e) {
      this.typesubmit = true;
      // stop here if form is invalid
      this.$v.$touch();
      if (this.$v.$invalid) {
        this.isError = true
        this.errorMessage = "please fill the form with valid data"
      } else {
        // validation
        this.isLocationNotSelected = this.form.location === null ? true : false;

        if (this.isLocationNotSelected) {
          return false;
        }

        // do your submit logic here
        let param = new FormData();
        param.append('name', this.form.name)
        param.append('capacity', this.form.capacity)
        param.append('description', this.form.description)
        param.append('location', this.form.location.locationId)
        this.$axios.post("/room", param)
        .then( (result) => {  
          let data = result.data.result.room 
          this.$router.push('/room/edit/'+data._id+'#treatment')
        }).catch(e => {
          if (e.response) {  
            this.isError = true
            this.errorMessage = e.response.data.message
          }
        })
      }
    }, 
    getLocation() {
      this.$axios.get(`location`, {
        param: {
          sortBy: "name",
          sortType: 1,
          limit: 100
        }
      }).then((response) => {
          let result = response.data.result.location;
          this.locations = result;
        });
    }
  }
};
</script>

<template>
  <Layout>
    <PageHeader :title="title" :items="items" />
    <div class="row">
      <div class="col-lg-12">
        <div class="card">
          <div class="card-body">
            <b-alert
              v-model="isError"
              variant="danger"
              class="mt-3"
              dismissible
            >{{errorMessage}}</b-alert>
            <form action="#" @submit.prevent="onSubmit">
              <div class="form-group">
                <label>Name</label>
                <input
                  v-model="form.name"
                  type="text"
               
                  class="form-control"
                  name="name"
                  :class="{ 'is-invalid': typesubmit && $v.form.name.$error }"
                />
                <div v-if="typesubmit && $v.form.name.$error" class="invalid-feedback">
                  <span v-if="!$v.form.name.required">This value is required.</span>
                </div>
              </div> 

              <div class="form-group">
                <label>Capacity</label> 
               
                  <input
                  v-model="form.capacity"
                  type="number"
           
                  class="form-control"
                  name="capacity"
                  :class="{ 'is-invalid': typesubmit && $v.form.capacity.$error }"
                />
               
                <div v-if="typesubmit && $v.form.capacity.$error" class="invalid-feedback">
                  <span v-if="!$v.form.capacity.required">This value is required.</span>
                </div>
              </div> 
              <div class="form-group">
                <label>Description</label>
                <textarea
                    v-model="form.description"
                    type="text" 
                    class="form-control" 
                    :class="{ 'is-invalid': typesubmit && $v.form.description.$error }"
                  /> 
                <div v-if="typesubmit && $v.form.description.$error" class="invalid-feedback">
                  <span v-if="!$v.form.description.required">This value is required.</span>
                </div>
              </div> 
              <div class="form-group">
                <label>Location</label>
                <multiselect :class="{ 'is-invalid': isLocationNotSelected }" v-model="form.location" deselect-label="Can't remove this value" placeholder="Select one" :options="locations" :searchable="false" :allow-empty="false">
                  <template slot="singleLabel" slot-scope="{ option }"><strong>{{ option.name | capitalize }}</strong></template>
                  <template slot="option" slot-scope="{ option }"><strong>{{ option.name | capitalize }}</strong></template>
                </multiselect>
                <div v-if="isLocationNotSelected" class="invalid-feedback">
                  <span>This value is required.</span>
                </div>
              </div> 
              <div class="form-group mb-0">
                <div>
                  <button type="submit" class="btn btn-primary">Submit</button>
                </div>
              </div>
            </form>
          </div>
          <!-- end card-body -->
        </div>
      </div>
      <!-- end col-->
    </div>
    <!-- end row -->
  </Layout>
</template>